import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MaterialModule } from '../material/material.module';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule
  ],
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() white = false;
  @Input() brand = false;

  // constructor() { }

  
}
